<!--
 * @Author: Akko
 * @Description: 其他信息
-->
<template>
    <div>
        <el-form ref="userForm" :model="userForm" label-width="100px">

            <!-- 居住信息 -->
            <div class="card-container">
                <div class="card-heard">居住信息</div>
                <div v-for="(domain, idx) in userForm.residenceList" :key="domain.id" class="block-box">
                    <div class="block-heard">
                        <p>居住信息{{ idx+1 }}</p>
                        <div v-if="idx!=0" class="del-cur" @click.prevent="removeMemner(domain)">删除</div>
                    </div>
                    <div class="live-box">
                        <p>自出生后曾居住12个月或更长时间的所有国家，【以护照上的出入境章为准】，如从未出国一年以上，则为“出生年月-至今”</p>
                        <div class="one-input">
                            <el-form-item label="国家:" :prop="'residenceList.'+idx+'.country'" :rules="[{required: true,message: '请输入国家名称',trigger: 'blur'}]">
                                <el-input v-model.trim="domain.country" class="full-width" placeholder="请输入国家名称" maxlength="100" clearable />
                            </el-form-item>
                        </div>
                        <div class="one-input">
                            <el-form-item label="开始时间:" :prop="'residenceList.'+idx+'.startTime'" :rules="[{required: true,message: '请选择开始时间',trigger: 'change'}]">
                                <el-date-picker v-model="domain.startTime" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM" class="full-width" type="month" placeholder="请选择" @change="(value) => pikerStartTime(value, idx)" />
                            </el-form-item>
                            <el-form-item label="结束时间:" :prop="'residenceList.'+idx+'.endTime'" :rules="[{required: true,message: '请选择结束时间',trigger: 'change'}]">
                                <el-date-picker v-if="!domain.endTimeChecked" v-model="domain.endTime" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM" class="end-width" type="month" placeholder="请选择" @change="(value) => pikerEndTime(value, idx)" />
                                <el-checkbox v-model="domain.endTimeChecked" @change="endTimeNow($event,domain)">至今</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="add-people" @click="addMember">+新增居住信息</div>
            </div>

            <!-- 语言能力 -->
            <div class="card-container">
                <div class="card-heard">语言能力</div>
                <div class="language-box">
                    <p class="language-p">您的语言水平与下列哪一项符合：</p>
                    <el-form-item class="same-line" label-width="0" prop="order.languageLevel" :rules="[{required: true,message: '请选择语言能力',trigger: 'change'}]">
                        <el-radio-group v-model="userForm.order.languageLevel">
                            <el-radio v-for="item in languageList" :key="item.id" :label="item.id">
                                <p>{{ item.tit }}</p>
                                <div>{{ item.dec }}</div>
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
            </div>

            <!-- 专业技能行业 -->
            <div class="card-container">
                <div class="card-heard">专业技能行业</div>
                <div class="industry-box">
                    <p class="language-p">1、最能代表你的专业技能的行业是（单选）</p>
                    <el-table ref="multipleTable" :data="industryList" style="width: 100%" :border="false" highlight-current-row @current-change="handleCurrentChange">
                        <el-table-column label="选项" width="50" align="center">
                            <template slot-scope="scope">
                                <el-radio v-model="scope.row.val" :label="scope.$index" />
                            </template>
                        </el-table-column>
                        <el-table-column label="编号" align="center" type="index" prop="messageTemplateBody" min-width="30" />
                        <el-table-column label="行业" prop="ind" min-width="40" />
                        <el-table-column label="例子" prop="example" min-width="100">
                            <template slot-scope="scope">
                                <span v-if="scope.row.example!=''">{{ scope.row.example }}</span>
                                <el-input v-else v-model.trim="tableRemake" maxlength="20" class="full-width" placeholder="请输入" clearable />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <!-- 辅助资料 -->
            <div class="card-container">
                <div class="card-heard">辅助资料 （请问您是否有以下【辅助性】资料）</div>
                <div class="auxiliary-box">
                    <div class="auxiliary-line">
                        <p>1、您是否有行业内的专业资格证书？</p>
                        <el-form-item class="same-line" label-width="0" prop="order.professionalQualification" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                            <el-radio-group v-model="userForm.order.professionalQualification">
                                <el-radio v-for="item in auxiliaryArr" :key="item.id" :label="item.val">{{ item.txt }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>

                    <div class="auxiliary-line">
                        <p>2、您是否在期刊或杂志上发表过论文、文章、书籍等（与工作、资质有关的）？</p>
                        <el-form-item class="same-line" label-width="0" prop="order.paper" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                            <el-radio-group v-model="userForm.order.paper">
                                <el-radio v-for="item in auxiliaryArr" :key="item.id" :label="item.val">{{ item.txt }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>

                    <div class="auxiliary-line">
                        <p>3、您是否有大学奖学金证明、专利证书、获奖证书（工作单位颁发的可以）？</p>
                        <el-form-item class="same-line" label-width="0" prop="order.scholarship" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                            <el-radio-group v-model="userForm.order.scholarship">
                                <el-radio v-for="item in auxiliaryArr" :key="item.id" :label="item.val">{{ item.txt }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>

                    <div class="auxiliary-line">
                        <p>4、您是否被媒体采访过，专访您的文章被发表在杂志上或网络上？</p>
                        <el-form-item class="same-line" label-width="0" prop="order.mediaInterview" :rules="[{required: true,message: '请选择',trigger: 'change'}]">
                            <el-radio-group v-model="userForm.order.mediaInterview">
                                <el-radio v-for="item in auxiliaryArr" :key="item.id" :label="item.val">{{ item.txt }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>

                </div>
            </div>
        </el-form>

        <!-- 保存按钮 -->
        <div class="operations-group">
            <el-button class="person-btn" @click="goPre">上一步</el-button>
            <el-button v-if="(user.baseSchedule) < 6" class="person-btn btn-blue" :loading="isSave" @click="saveForm('save')">保存</el-button>
            <el-button class="person-btn btn-blue" :loading="isNext" @click="saveForm('next')">下一步</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import { saveResidence } from "@/api/workfow.js";
import { mapActions, mapState } from "vuex";
export default {
    name: "Other",
    components: {},
    data() {
        return {
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
            auxiliaryArr: [
                {
                    txt: "是",
                    val: 1,
                },
                {
                    txt: "否",
                    val: 2,
                },
                {
                    txt: "不确定",
                    val: 3,
                },
            ],
            languageList: [
                {
                    id: 1,
                    tit: "中文及英文",
                    dec: "国外大学（英语国家）毕业，英文授课，获得学位证。（如为中外联合办学，在中国上课的，不算）",
                },
                {
                    id: 2,
                    tit: "中文及英文",
                    dec: " 有近两年的雅思或托福成绩单（雅思考试G类或A类总分达6分；托福笔试达550分，计算机达213分，网考达80分）",
                },
                // {
                //     id:3,
                //     tit: "中文及英文",
                //     dec: " 不满足以上两个条件，但英文基础较好，有能力在近期参加雅思或托福考试并获取合格成绩单。成绩单可在递交申请后的三个月之内补交（您可报读短期辅导培训班补习英文，然后马上参加考试）。",
                // },
                {
                    id: 4,
                    tit: "中文及另一种外语",
                    dec: "非英文的外语，如：法文、日文等（需提供相关证书）",
                },
                {
                    id: 5,
                    tit: "仅中文",
                    dec: "",
                },
            ],
            industryList: [
                {
                    ind: "学术研究及教育",
                    example: "幼儿教育、高等教育、学术研究",
                    val: "",
                },
                {
                    ind: "建筑、测量、工程及建造",
                    example:
                        "园景设计、建筑、测量、土木/电机/机器/机构/环境工程",
                    val: "",
                },
                {
                    ind: "艺术及文化",
                    example: "表演艺术、美术、博物馆、图书馆、摄影",
                    val: "",
                },
                {
                    ind: "广播及娱乐",
                    example:
                        "广播及节目制作、电影、录像及电视制作、唱片及音乐出版",
                    val: "",
                },
                {
                    ind: "业务支援及人力资源",
                    example:
                        "业务咨询顾问、公司秘书、行政支援、人事管理、招聘服务、公关关系服务、广告、信息服务",
                    val: "",
                },
                {
                    ind: "餐饮服务及旅游",
                    example: "酒吧、餐厅、酒店、旅行代理",
                    val: "",
                },
                {
                    ind: "商业及贸易",
                    example: "进出口、零售、批发",
                    val: "",
                },
                {
                    ind: "金融及会计服务",
                    example: "会计、银行、保险、证券、投资银行",
                    val: "",
                },
                {
                    ind: "医疗保健及兽医服务",
                    example:
                        "西医药、中医药、牙科服务、医学科技、职业治疗、护理、兽医服务",
                    val: "",
                },
                {
                    ind: "资讯科技及电讯",
                    example: "资讯科技顾问、固网及无线通信",
                    val: "",
                },
                {
                    ind: "法律服务",
                    example: "法律事务",
                    val: "",
                },
                {
                    ind: "物流运输",
                    example: "物流、空运、海运、陆运",
                    val: "",
                },
                {
                    ind: "工业制造",
                    example:
                        "电机、电子、食物及饮料、化工、金属、玩具、纺织、钟表、珠宝、印刷及出版",
                    val: "",
                },
                {
                    ind: "体育运动",
                    example: "体育运动",
                    val: "",
                },
                {
                    ind: "其他",
                    example: "",
                    val: "",
                },
            ],
            userForm: {
                option: "",
                residenceList: [
                    {
                        orderId: 0,
                        country: "", //城市
                        startTime: "", //开始时间
                        endTime: "", //离开时间
                    },
                ],
                order: {
                    id: 0, //订单id
                    languageLevel: "", //语言等级
                    profession: "", //最能你代表你的行业 编号
                    professionOther: "", //最能你代表你的行业 描述
                    professionalQualification: "", //您是否有行业内的专业资格证书？(3-不确定 1-是 2-否)
                    paper: "", //您是否在期刊或杂志上发表过论文、文章、书籍等（与工作、资质有关的）？(3-不确定 1-是 2-否)
                    scholarship: "", //您是否有大学奖学金证明、专利证书、获奖证书（工作单位颁发的可以）(3-不确定 1-是 2-否)
                    mediaInterview: "", //您是否被媒体采访过，专访您的文章被发表在杂志上或网络上？(3-不确定 1-是 2-否)
                },
            },
            oneChoose: false,
            tableRemake: "",
            isSave: false, //防抖loading
            isNext: false,
        };
    },
    computed: {
        ...mapState(["user"])
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["getInfoData"]),
        // 刷新数据
        dataEcho() {
            this.getInfoData(this.$route.params.id).then((res) => {
                let resd = res.data;
                if (res.code === 200) {
                    if (resd.residenceList.length === 0) {
                        this.userForm.residenceList = [
                            {
                                orderId: this.$route.params.id,
                                country: "",
                                startTime: resd.information.birthday.slice(
                                    0,
                                    7
                                ),
                                endTime: "",
                            },
                        ];
                    } else {
                        resd.residenceList.forEach((ele) => {
                            // 判断是否至今
                            if (ele.endTime === "至今") {
                                ele.endTimeChecked = true;
                                // ele.endTime = "2021-04-02";
                                ele.endTime = this.$utils.today();
                            } else {
                                ele.endTimeChecked = false;
                            }
                        });
                        this.userForm.residenceList = resd.residenceList;
                    }

                    this.userForm.order = {
                        id: resd.order.id,
                        languageLevel:
                            resd.order.languageLevel === 0
                                ? ""
                                : resd.order.languageLevel,
                        profession: resd.order.profession,
                        professionOther: resd.order.professionOther,
                        professionalQualification:
                            resd.order.professionalQualification === 0
                                ? ""
                                : resd.order.professionalQualification,
                        paper: resd.order.paper === 0 ? "" : resd.order.paper,
                        scholarship:
                            resd.order.scholarship === 0
                                ? ""
                                : resd.order.scholarship,
                        mediaInterview:
                            resd.order.mediaInterview === 0
                                ? ""
                                : resd.order.mediaInterview,
                    };
                    if (resd.order.profession !== "") {
                        this.industryList[
                            Number(resd.order.profession) - 1
                        ].val = Number(resd.order.profession) - 1;
                    }
                    this.tableRemake =
                        resd.order.profession === "15"
                            ? this.userForm.order.professionOther
                            : "";
                }
            });
        },
        // 单选最能代表你的专业技能的行业
        handleCurrentChange(val) {
            let num = this.industryList.indexOf(val);
            this.industryList.forEach((ele) => {
                ele.val = "";
            });
            this.industryList[num].val = num;
            if (num < 14) this.tableRemake = "";
            this.userForm.order.profession = num + 1;
        },
        // 禁止开始时间乱选
        pikerStartTime(e, idx) {
            let startDate = e === null ? "" : e;
            let endDate = this.userForm.residenceList[idx].endTime;
            if (endDate !== "" && endDate !== null) {
                let result =
                    new Date(endDate).getTime() > new Date(startDate).getTime();
                if (!result) {
                    this.userForm.residenceList[idx].startTime = "";
                    this.$message.error("开始时间不得在结束时间之后");
                }
            }
        },
        // 禁止结束时间乱选
        pikerEndTime(e, idx) {
            let startDate = this.userForm.residenceList[idx].startTime;
            let endDate = e === null ? "" : e;
            if (startDate !== "" && startDate !== null) {
                let result =
                    new Date(startDate).getTime() < new Date(endDate).getTime();
                if (!result) {
                    this.userForm.residenceList[idx].endTime = "";
                    this.$message.error("结束时间不得在开始时间之前");
                }
            }
        },
        // 至今选择
        endTimeNow(e, item) {
            if (e) {
                // item.endTime = "2021-04-02"; //占位
                item.endTime = this.$utils.today(); //占位
            } else {
                item.endTime = "";
            }
        },
        // 添加居住信息
        addMember() {
            this.userForm.residenceList.push({
                orderId: this.$route.params.id,
                country: "",
                startTime: "",
                endTime: "",
                key: Date.now(),
            });
        },
        // 删除居住信息
        removeMemner(item) {
            let index = this.userForm.residenceList.indexOf(item);
            if (index !== -1) {
                this.userForm.residenceList.splice(index, 1);
            }
        },
        // 判断是保存还是下一步
        saveForm(txt) {
            localStorage.setItem("stepChange", 0);
            this.$store.commit("stepChange");
            // 处理是否至今
            this.userForm.residenceList.forEach((ele) => {
                if (
                    ele.endTimeChecked != undefined &&
                    ele.endTimeChecked != false
                ) {
                    ele.endTime = "至今";
                }
            });
            if (txt === "save") {
                this.isSave = true;
                if (this.userForm.order.profession == 15) {
                    this.userForm.order.professionOther = this.tableRemake;
                } else if (this.userForm.order.profession !== "") {
                    this.userForm.order.professionOther =
                        this.industryList[
                            Number(this.userForm.order.profession) - 1
                        ].ind;
                }
                this.userForm.option = "save";
                return this.hanledSaveQuestion(6);
            } else {
                this.userForm.option = "submit";
                this.$refs.userForm.validate((valid) => {
                    if (valid) {
                        if (this.userForm.order.profession === "") {
                            this.$message.error("请选择你的专业技能行业");
                        } else {
                            // 处理所属 行业其他
                            if (this.userForm.order.profession == 15) {
                                if (this.tableRemake === "") {
                                    this.$message.error(
                                        "请输入其他专业技能行业"
                                    );
                                    return false;
                                } else {
                                    this.userForm.order.professionOther =
                                        this.tableRemake;
                                    return this.hanledSaveQuestion(7);
                                }
                            } else {
                                this.userForm.order.professionOther =
                                    this.industryList[
                                        Number(this.userForm.order.profession) -
                                            1
                                    ].ind;
                                this.isNext = true;
                                return this.hanledSaveQuestion(7);
                            }
                        }
                    } else {
                        return this.$message.error("请完善信息");
                    }
                });
            }
        },
        // 上一步
        goPre() {
            this.$router.replace({
                name: "step5",
            });
        },
        // 发起保存动作
        hanledSaveQuestion(parmas) {
            return new Promise((resolve, reject) => {
                saveResidence(this.userForm).then((res) => {
                    this.isNext = false;
                    if (res.code === 200) {
                        // 切换步骤保存 下一步的时候才设置
                        if (this.userForm.option === "submit") {
                            this.saveUserData(parmas);
                            this.$message.success("提交成功！");
                            this.$router.replace({
                                name: "step" + parmas,
                            });
                        } else {
                            this.$message.success("保存成功！");
                            this.dataEcho();
                            this.isSave = false;
                        }
                        resolve(res);
                    } else {
                        reject(res);
                    }
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.one-input {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.add-people {
    width: 928px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #dcdfe6;
    color: #409eff;
    font-size: 14px;
    text-align: center;
    margin: 16px auto;
    cursor: pointer;
}

.block-box {
    padding-bottom: 6px;
}

.full-width {
    width: 360px;
}

.end-width {
    width: 303px;
    margin-right: 10px;
}

.live-box {
    padding: 16px 16px 0 16px;

    > p {
        font-size: 14px;
        color: #606266;
        margin-bottom: 24px;
    }
}

.language-p {
    font-size: 14px;
    color: #303133;
    margin: 16px 0 17px 0;
    position: relative;
    padding-left: 12px;

    &::before {
        content: "*";
        position: absolute;
        left: 0;
        top: 2px;
        color: red;
    }
}

.auxiliary-line > p {
    position: relative;
    padding-left: 12px;

    &::before {
        content: "*";
        position: absolute;
        left: 0;
        top: 2px;
        color: red;
    }
}

.language-box {
    padding: 0 16px;
    color: #606266;

    /deep/ .el-radio__label {
        white-space: initial;
        line-height: 20px;
    }

    .el-radio {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 17px;
    }

    p {
        margin-bottom: 4px;
    }
}

.industry-box {
    padding: 0 16px;
    position: relative;

    /deep/.el-radio__label {
        display: none;
    }
}

.auxiliary-box {
    padding: 16px 16px 0;

    p {
        font-size: 14px;
        color: #303133;
        margin-bottom: 6px;
    }
}

.del-cur {
    cursor: pointer;
}

.industry-radio {
    width: 20px;
    position: absolute;
    left: 17px;
    top: 69px;
    z-index: 2;

    .el-radio {
        margin-bottom: 24.5px;
    }

    /deep/.el-radio__label {
        display: none;
    }
}
</style>
